import { useLocation } from "react-router-dom";

const useRouteUtils = () => {
  const location = useLocation();

  const checkIfActiveRoute = (route, exact) => {
    return exact
      ? location.pathname === route
      : location.pathname.includes(route);
  };
  return {
    checkIfActiveRoute,
  };
};

export default useRouteUtils;
