import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Appearance",
      label: "appearance",
      link: "/Settings",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">palette</span>,
      active: checkIfActiveRoute("/Settings", true),
    },
  ];
  return {
    drawerMenu: drawerMenu,
  };
};

export default useSettings;
