const countryData = [
    {
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      label: "+91",
      value: "+91",
    },
    {
      name: "Afghanistan",
      flag: "🇦🇫",
      code: "AF",
      label: "+93",
      value: "+93",
    },
    {
      name: "Saudi Arabia",
      flag: "🇸🇦",
      code: "SA",
      label: "+966",
      value: "+966",
    },
    {
      name: "New Zealand",
      flag: "🇳🇿",
      code: "NZ",
      label: "+64",
      value: "+64",
    },
    {
      name: "United Kingdom",
      flag: "🇬🇧",
      code: "GB",
      label: "+44",
      value: "+44",
    },
    {
      name: "United Arab Emirates",
      flag: "🇦🇪",
      code: "AE",
      label: "+971",
      value: "+971",
    },
    {
      name: "Canada",
      flag: "🇨🇦",
      code: "CA",
      label: "+1",
      value: "+1",
    },
    {
      name: "Åland Islands",
      flag: "🇦🇽",
      code: "AX",
      label: "+358",
      value: "+358",
    },
    {
      name: "Albania",
      flag: "🇦🇱",
      code: "AL",
      label: "+355",
      value: "+355",
    },
    {
      name: "Algeria",
      flag: "🇩🇿",
      code: "DZ",
      label: "+213",
      value: "+213",
    },
    {
      name: "Iraq",
      flag: "🇮🇶",
      code: "IQ",
      label: "+964",
      value: "+964",
    },
    {
      name: "American Samoa",
      flag: "🇦🇸",
      code: "AS",
      label: "+1684",
      value: "+1684",
    },
    {
      name: "Andorra",
      flag: "🇦🇩",
      code: "AD",
      label: "+376",
      value: "+376",
    },
    {
      name: "Angola",
      flag: "🇦🇴",
      code: "AO",
      label: "+244",
      value: "+244",
    },
    {
      name: "Anguilla",
      flag: "🇦🇮",
      code: "AI",
      label: "+1264",
      value: "+1264",
    },
    {
      name: "Antarctica",
      flag: "🇦🇶",
      code: "AQ",
      label: "+672",
      value: "+672",
    },
    {
      name: "Antigua and Barbuda",
      flag: "🇦🇬",
      code: "AG",
      label: "+1268",
      value: "+1268",
    },
    {
      name: "Argentina",
      flag: "🇦🇷",
      code: "AR",
      label: "+54",
      value: "+54",
    },
    {
      name: "Armenia",
      flag: "🇦🇲",
      code: "AM",
      label: "+374",
      value: "+374",
    },
    {
      name: "Aruba",
      flag: "🇦🇼",
      code: "AW",
      label: "+297",
      value: "+297",
    },
    {
      name: "Australia",
      flag: "🇦🇺",
      code: "AU",
      label: "+61",
      value: "+61",
    },
    {
      name: "Austria",
      flag: "🇦🇹",
      code: "AT",
      label: "+43",
      value: "+43",
    },
    {
      name: "Azerbaijan",
      flag: "🇦🇿",
      code: "AZ",
      label: "+994",
      value: "+994",
    },
    {
      name: "Bahamas",
      flag: "🇧🇸",
      code: "BS",
      label: "+1242",
      value: "+1242",
    },
    {
      name: "Bahrain",
      flag: "🇧🇭",
      code: "BH",
      label: "+973",
      value: "+973",
    },
    {
      name: "Bangladesh",
      flag: "🇧🇩",
      code: "BD",
      label: "+880",
      value: "+880",
    },
    {
      name: "Barbados",
      flag: "🇧🇧",
      code: "BB",
      label: "+1246",
      value: "+1246",
    },
    {
      name: "Belarus",
      flag: "🇧🇾",
      code: "BY",
      label: "+375",
      value: "+375",
    },
    {
      name: "Belgium",
      flag: "🇧🇪",
      code: "BE",
      label: "+32",
      value: "+32",
    },
    {
      name: "Belize",
      flag: "🇧🇿",
      code: "BZ",
      label: "+501",
      value: "+501",
    },
    {
      name: "Benin",
      flag: "🇧🇯",
      code: "BJ",
      label: "+229",
      value: "+229",
    },
    {
      name: "Bermuda",
      flag: "🇧🇲",
      code: "BM",
      label: "+1441",
      value: "+1441",
    },
    {
      name: "Bhutan",
      flag: "🇧🇹",
      code: "BT",
      label: "+975",
      value: "+975",
    },
    {
      name: "Bolivia, Plurinational State of bolivia",
      flag: "🇧🇴",
      code: "BO",
      label: "+59",
      value: "+591",
    },
    {
      name: "Bosnia and Herzegovina",
      flag: "🇧🇦",
      code: "BA",
      label: "+387",
      value: "+387",
    },
    {
      name: "Botswana",
      flag: "🇧🇼",
      code: "BW",
      label: "+267",
      value: "+267",
    },
    {
      name: "Bouvet Island",
      flag: "🇧🇻",
      code: "BV",
      label: "+47",
      value: "+47",
    },
    {
      name: "Brazil",
      flag: "🇧🇷",
      code: "BR",
      label: "+55",
      value: "+55",
    },
    {
      name: "British Indian Ocean Territory",
      flag: "🇮🇴",
      code: "IO",
      label: "+246",
      value: "+246",
    },
    {
      name: "Brunei Darussalam",
      flag: "🇧🇳",
      code: "BN",
      label: "+673",
      value: "+673",
    },
    {
      name: "Bulgaria",
      flag: "🇧🇬",
      code: "BG",
      label: "+359",
      value: "+359",
    },
    {
      name: "Burkina Faso",
      flag: "🇧🇫",
      code: "BF",
      label: "+226",
      value: "+226",
    },
    {
      name: "Burundi",
      flag: "🇧🇮",
      code: "BI",
      label: "+257",
      value: "+257",
    },
    {
      name: "Cambodia",
      flag: "🇰🇭",
      code: "KH",
      label: "+855",
      value: "+855",
    },
    {
      name: "Cameroon",
      flag: "🇨🇲",
      code: "CM",
      label: "+237",
      value: "+237",
    },
    {
      name: "Canada",
      flag: "🇨🇦",
      code: "CA",
      label: "+1",
      value: "+1",
    },
    {
      name: "Cape Verde",
      flag: "🇨🇻",
      code: "CV",
      label: "+238",
      value: "+238",
    },
    {
      name: "Cayman Islands",
      flag: "🇰🇾",
      code: "KY",
      label: "+345",
      value: "+345",
    },
    {
      name: "Central African Republic",
      flag: "🇨🇫",
      code: "CF",
      label: "+236",
      value: "+236",
    },
    {
      name: "Chad",
      flag: "🇹🇩",
      code: "TD",
      label: "+235",
      value: "+235",
    },
    {
      name: "Chile",
      flag: "🇨🇱",
      code: "CL",
      label: "+56",
      value: "+56",
    },
    {
      name: "China",
      flag: "🇨🇳",
      code: "CN",
      label: "+86",
      value: "+86",
    },
    {
      name: "Christmas Island",
      flag: "🇨🇽",
      code: "CX",
      label: "+61",
      value: "+61",
    },
    {
      name: "Cocos (Keeling) Islands",
      flag: "🇨🇨",
      code: "CC",
      label: "+61",
      value: "+61",
    },
    {
      name: "Colombia",
      flag: "🇨🇴",
      code: "CO",
      label: "+57",
      value: "+57",
    },
    {
      name: "Comoros",
      flag: "🇰🇲",
      code: "KM",
      label: "+269",
      value: "+269",
    },
    {
      name: "Congo",
      flag: "🇨🇬",
      code: "CG",
      label: "+242",
      value: "+242",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      flag: "🇨🇩",
      code: "CD",
      label: "+243",
      value: "+243",
    },
    {
      name: "Cook Islands",
      flag: "🇨🇰",
      code: "CK",
      label: "+682",
      value: "+682",
    },
    {
      name: "Costa Rica",
      flag: "🇨🇷",
      code: "CR",
      label: "+506",
      value: "+506",
    },
    {
      name: "Cote d'Ivoire",
      flag: "🇨🇮",
      code: "CI",
      label: "+225",
      value: "+225",
    },
    {
      name: "Croatia",
      flag: "🇭🇷",
      code: "HR",
      label: "+385",
      value: "+385",
    },
    {
      name: "Cuba",
      flag: "🇨🇺",
      code: "CU",
      label: "+53",
      value: "+53",
    },
    {
      name: "Cyprus",
      flag: "🇨🇾",
      code: "CY",
      label: "+357",
      value: "+357",
    },
    {
      name: "Czech Republic",
      flag: "🇨🇿",
      code: "CZ",
      label: "+420",
      value: "+420",
    },
    {
      name: "Denmark",
      flag: "🇩🇰",
      code: "DK",
      label: "+45",
      value: "+45",
    },
    {
      name: "Djibouti",
      flag: "🇩🇯",
      code: "DJ",
      label: "+253",
      value: "+253",
    },
    {
      name: "Dominica",
      flag: "🇩🇲",
      code: "DM",
      label: "+1767",
      value: "+1767",
    },
    {
      name: "Dominican Republic",
      flag: "🇩🇴",
      code: "DO",
      label: "+1849",
      value: "+1849",
    },
    {
      name: "Ecuador",
      flag: "🇪🇨",
      code: "EC",
      label: "+593",
      value: "+593",
    },
    {
      name: "Egypt",
      flag: "🇪🇬",
      code: "EG",
      label: "+20",
      value: "+20",
    },
    {
      name: "El Salvador",
      flag: "🇸🇻",
      code: "SV",
      label: "+503",
      value: "+503",
    },
    {
      name: "Equatorial Guinea",
      flag: "🇬🇶",
      code: "GQ",
      label: "+240",
      value: "+240",
    },
    {
      name: "Eritrea",
      flag: "🇪🇷",
      code: "ER",
      label: "+291",
      value: "+291",
    },
    {
      name: "Estonia",
      flag: "🇪🇪",
      code: "EE",
      label: "+372",
      value: "+372",
    },
    {
      name: "Ethiopia",
      flag: "🇪🇹",
      code: "ET",
      label: "251",
      value: "+251",
    },
    {
      name: "Falkland Islands (Malvinas)",
      flag: "🇫🇰",
      code: "FK",
      label: "+500",
      value: "+500",
    },
    {
      name: "Faroe Islands",
      flag: "🇫🇴",
      code: "FO",
      label: "298",
      value: "+298",
    },
    {
      name: "Fiji",
      flag: "🇫🇯",
      code: "FJ",
      label: "+679",
      value: "+679",
    },
    {
      name: "Finland",
      flag: "🇫🇮",
      code: "FI",
      label: "+358",
      value: "+358",
    },
    {
      name: "France",
      flag: "🇫🇷",
      code: "FR",
      label: "33",
      value: "+33",
    },
    {
      name: "French Guiana",
      flag: "🇬🇫",
      code: "GF",
      label: "594",
      value: "+594",
    },
    {
      name: "French Polynesia",
      flag: "🇵🇫",
      code: "PF",
      label: "+689",
      value: "+689",
    },
    {
      name: "French Southern Territories",
      flag: "🇹🇫",
      code: "TF",
      label: "262",
      value: "+262",
    },
    {
      name: "Gabon",
      flag: "🇬🇦",
      code: "GA",
      label: "+241",
      value: "+241",
    },
    {
      name: "Gambia",
      flag: "🇬🇲",
      code: "GM",
      label: "+220",
      value: "+220",
    },
    {
      name: "Georgia",
      flag: "🇬🇪",
      code: "GE",
      label: "+995",
      value: "+995",
    },
    {
      name: "Germany",
      flag: "🇩🇪",
      code: "DE",
      label: "+49",
      value: "+49",
    },
    {
      name: "Ghana",
      flag: "🇬🇭",
      code: "GH",
      label: "+233",
      value: "+233",
    },
    {
      name: "Gibraltar",
      flag: "🇬🇮",
      code: "GI",
      label: "+350",
      value: "+350",
    },
    {
      name: "Greece",
      flag: "🇬🇷",
      code: "GR",
      label: "+30",
      value: "+30",
    },
    {
      name: "Greenland",
      flag: "🇬🇱",
      code: "GL",
      label: "+299",
      value: "+299",
    },
    {
      name: "Grenada",
      flag: "🇬🇩",
      code: "GD",
      label: "+1473",
      value: "+1473",
    },
    {
      name: "Guadeloupe",
      flag: "🇬🇵",
      code: "GP",
      label: "+590",
      value: "+590",
    },
    {
      name: "Guam",
      flag: "🇬🇺",
      code: "GU",
      label: "+1671",
      value: "+1671",
    },
    {
      name: "Guatemala",
      flag: "🇬🇹",
      code: "GT",
      label: "+502",
      value: "+502",
    },
    {
      name: "Guernsey",
      flag: "🇬🇬",
      code: "GG",
      label: "+44",
      value: "+44",
    },
    {
      name: "Guinea",
      flag: "🇬🇳",
      code: "GN",
      label: "+224",
      value: "+224",
    },
    {
      name: "Guinea-Bissau",
      flag: "🇬🇼",
      code: "GW",
      label: "245",
      value: "+245",
    },
    {
      name: "Guyana",
      flag: "🇬🇾",
      code: "GY",
      label: "+592",
      value: "+592",
    },
    {
      name: "Haiti",
      flag: "🇭🇹",
      code: "HT",
      label: "+509",
      value: "+509",
    },
    {
      name: "Heard Island and Mcdonald Islands",
      flag: "🇭🇲",
      code: "HM",
      label: "+672",
      value: "+672",
    },
    {
      name: "Holy See (Vatican City State)",
      flag: "🇻🇦",
      code: "VA",
      label: "+379",
      value: "+379",
    },
    {
      name: "Honduras",
      flag: "🇭🇳",
      code: "HN",
      label: "+504",
      value: "+504",
    },
    {
      name: "Hong Kong",
      flag: "🇭🇰",
      code: "HK",
      label: "+852",
      value: "+852",
    },
    {
      name: "Hungary",
      flag: "🇭🇺",
      code: "HU",
      label: "+36",
      value: "+36",
    },
    {
      name: "Iceland",
      flag: "🇮🇸",
      code: "IS",
      label: "+354",
      value: "+354",
    },
    {
      name: "Indonesia",
      flag: "🇮🇩",
      code: "ID",
      label: "+62",
      value: "+62",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      flag: "🇮🇷",
      code: "IR",
      label: "+98",
      value: "+98",
    },
  
    {
      name: "Ireland",
      flag: "🇮🇪",
      code: "IE",
      label: "+353",
      value: "+353",
    },
    {
      name: "Isle of Man",
      flag: "🇮🇲",
      code: "IM",
      label: "+44",
      value: "+44",
    },
    {
      name: "Israel",
      flag: "🇮🇱",
      code: "IL",
      label: "+972",
      value: "+972",
    },
    {
      name: "Italy",
      flag: "🇮🇹",
      code: "IT",
      label: "+39",
      value: "+39",
    },
    {
      name: "Jamaica",
      flag: "🇯🇲",
      code: "JM",
      label: "+1876",
      value: "+1876",
    },
    {
      name: "Japan",
      flag: "🇯🇵",
      code: "JP",
      label: "+81",
      value: "+81",
    },
    {
      name: "Jersey",
      flag: "🇯🇪",
      code: "JE",
      label: "+44",
      value: "+44",
    },
    {
      name: "Jordan",
      flag: "🇯🇴",
      code: "JO",
      label: "+962",
      value: "+962",
    },
    {
      name: "Kazakhstan",
      flag: "🇰🇿",
      code: "KZ",
      label: "+7",
      value: "+7",
    },
    {
      name: "Kenya",
      flag: "🇰🇪",
      code: "KE",
      label: "+254",
      value: "+254",
    },
    {
      name: "Kiribati",
      flag: "🇰🇮",
      code: "KI",
      label: "+686",
      value: "+686",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      flag: "🇰🇵",
      code: "KP",
      label: "+850",
      value: "+850",
    },
    {
      name: "Korea, Republic of South Korea",
      flag: "🇰🇷",
      code: "KR",
      label: "+82",
      value: "+82",
    },
    {
      name: "Kosovo",
      flag: "🇽🇰",
      code: "XK",
      label: "+383",
      value: "+383",
    },
    {
      name: "Kuwait",
      flag: "🇰🇼",
      code: "KW",
      label: "+965",
      value: "+965",
    },
    {
      name: "Kyrgyzstan",
      flag: "🇰🇬",
      code: "KG",
      label: "996",
      value: "+996",
    },
    {
      name: "Laos",
      flag: "🇱🇦",
      code: "LA",
      label: "+856",
      value: "+856",
    },
    {
      name: "Latvia",
      flag: "🇱🇻",
      code: "LV",
      label: "+371",
      value: "+371",
    },
    {
      name: "Lebanon",
      flag: "🇱🇧",
      code: "LB",
      label: "+961",
      value: "+961",
    },
    {
      name: "Lesotho",
      flag: "🇱🇸",
      code: "LS",
      label: "+266",
      value: "+266",
    },
    {
      name: "Liberia",
      flag: "🇱🇷",
      code: "LR",
      label: "+231",
      value: "+231",
    },
    {
      name: "Libyan Arab Jamahiriya",
      flag: "🇱🇾",
      code: "LY",
      label: "+218",
      value: "+218",
    },
    {
      name: "Liechtenstein",
      flag: "🇱🇮",
      code: "LI",
      label: "+423",
      value: "+423",
    },
    {
      name: "Lithuania",
      flag: "🇱🇹",
      code: "LT",
      label: "+370",
      value: "+370",
    },
    {
      name: "Luxembourg",
      flag: "🇱🇺",
      code: "LU",
      label: "+352",
      value: "+352",
    },
    {
      name: "Macao",
      flag: "🇲🇴",
      code: "MO",
      label: "+853",
      value: "+853",
    },
    {
      name: "Macedonia",
      flag: "🇲🇰",
      code: "MK",
      label: "+38",
      value: "+389",
    },
    {
      name: "Madagascar",
      flag: "🇲🇬",
      code: "MG",
      label: "+261",
      value: "+261",
    },
    {
      name: "Malawi",
      flag: "🇲🇼",
      code: "MW",
      label: "+265",
      value: "+265",
    },
    {
      name: "Malaysia",
      flag: "🇲🇾",
      code: "MY",
      label: "+60",
      value: "+60",
    },
    {
      name: "Maldives",
      flag: "🇲🇻",
      code: "MV",
      label: "960",
      value: "+960",
    },
    {
      name: "Mali",
      flag: "🇲🇱",
      code: "ML",
      label: "+223",
      value: "+223",
    },
    {
      name: "Malta",
      flag: "🇲🇹",
      code: "MT",
      label: "+356",
      value: "+356",
    },
    {
      name: "Marshall Islands",
      flag: "🇲🇭",
      code: "MH",
      label: "+692",
      value: "+692",
    },
    {
      name: "Martinique",
      flag: "🇲🇶",
      code: "MQ",
      label: "596",
      value: "+596",
    },
    {
      name: "Mauritania",
      flag: "🇲🇷",
      code: "MR",
      label: "+222",
      value: "+222",
    },
    {
      name: "Mauritius",
      flag: "🇲🇺",
      code: "MU",
      label: "+230",
      value: "+230",
    },
    {
      name: "Mayotte",
      flag: "🇾🇹",
      code: "YT",
      label: "+262",
      value: "+262",
    },
    {
      name: "Mexico",
      flag: "🇲🇽",
      code: "MX",
      label: "+52",
      value: "+52",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      flag: "🇫🇲",
      code: "FM",
      label: "+691",
      value: "+691",
    },
    {
      name: "Moldova",
      flag: "🇲🇩",
      code: "MD",
      label: "+373",
      value: "+373",
    },
    {
      name: "Monaco",
      flag: "🇲🇨",
      code: "MC",
      label: "+377",
      value: "+377",
    },
    {
      name: "Mongolia",
      flag: "🇲🇳",
      code: "MN",
      label: "+976",
      value: "+976",
    },
    {
      name: "Montenegro",
      flag: "🇲🇪",
      code: "ME",
      label: "+382",
      value: "+382",
    },
    {
      name: "Montserrat",
      flag: "🇲🇸",
      code: "MS",
      label: "+1664",
      value: "+1664",
    },
    {
      name: "Morocco",
      flag: "🇲🇦",
      code: "MA",
      label: "+212",
      value: "+212",
    },
    {
      name: "Mozambique",
      flag: "🇲🇿",
      code: "MZ",
      label: "+258",
      value: "+258",
    },
    {
      name: "Myanmar",
      flag: "🇲🇲",
      code: "MM",
      label: "+95",
      value: "+95",
    },
    {
      name: "Namibia",
      flag: "🇳🇦",
      code: "NA",
      label: "+264",
      value: "+264",
    },
    {
      name: "Nauru",
      flag: "🇳🇷",
      code: "NR",
      label: "+674",
      value: "+674",
    },
    {
      name: "Nepal",
      flag: "🇳🇵",
      code: "NP",
      label: "+977",
      value: "+977",
    },
    {
      name: "Netherlands",
      flag: "🇳🇱",
      code: "NL",
      label: "+31",
      value: "+31",
    },
    {
      name: "Netherlands Antilles",
      flag: "",
      code: "AN",
      label: "+599",
      value: "+599",
    },
    {
      name: "New Caledonia",
      flag: "🇳🇨",
      code: "NC",
      label: "+687",
      value: "+687",
    },
    {
      name: "New Zealand",
      flag: "🇳🇿",
      code: "NZ",
      label: "+64",
      value: "+64",
    },
    {
      name: "Nicaragua",
      flag: "🇳🇮",
      code: "NI",
      label: "+505",
      value: "+505",
    },
    {
      name: "Niger",
      flag: "🇳🇪",
      code: "NE",
      label: "+227",
      value: "+227",
    },
    {
      name: "Nigeria",
      flag: "🇳🇬",
      code: "NG",
      label: "+234",
      value: "+234",
    },
    {
      name: "Niue",
      flag: "🇳🇺",
      code: "NU",
      label: "+683",
      value: "+683",
    },
    {
      name: "Norfolk Island",
      flag: "🇳🇫",
      code: "NF",
      label: "+672",
      value: "+672",
    },
    {
      name: "Northern Mariana Islands",
      flag: "🇲🇵",
      code: "MP",
      label: "+1670",
      value: "+1670",
    },
    {
      name: "Norway",
      flag: "🇳🇴",
      code: "NO",
      label: "+4",
      value: "+47",
    },
    {
      name: "Oman",
      flag: "🇴🇲",
      code: "OM",
      label: "+968",
      value: "+968",
    },
    {
      name: "Pakistan",
      flag: "🇵🇰",
      code: "PK",
      label: "+92",
      value: "+92",
    },
    {
      name: "Palau",
      flag: "🇵🇼",
      code: "PW",
      label: "+680",
      value: "+680",
    },
    {
      name: "Palestinian Territory, Occupied",
      flag: "🇵🇸",
      code: "PS",
      label: "+970",
      value: "+970",
    },
    {
      name: "Panama",
      flag: "🇵🇦",
      code: "PA",
      label: "+507",
      value: "+507",
    },
    {
      name: "Papua New Guinea",
      flag: "🇵🇬",
      code: "PG",
      label: "+675",
      value: "+675",
    },
    {
      name: "Paraguay",
      flag: "🇵🇾",
      code: "PY",
      label: "+595",
      value: "+595",
    },
    {
      name: "Peru",
      flag: "🇵🇪",
      code: "PE",
      label: "+51",
      value: "+51",
    },
    {
      name: "Philippines",
      flag: "🇵🇭",
      code: "PH",
      label: "+63",
      value: "+63",
    },
    {
      name: "Pitcairn",
      flag: "🇵🇳",
      code: "PN",
      label: "+64",
      value: "+64",
    },
    {
      name: "Poland",
      flag: "🇵🇱",
      code: "PL",
      label: "+48",
      value: "+48",
    },
    {
      name: "Portugal",
      flag: "🇵🇹",
      code: "PT",
      label: "+351",
      value: "+351",
    },
    {
      name: "Puerto Rico",
      flag: "🇵🇷",
      code: "PR",
      label: "+1939",
      value: "+1939",
    },
    {
      name: "Qatar",
      flag: "🇶🇦",
      code: "QA",
      label: "+974",
      value: "+974",
    },
    {
      name: "Romania",
      flag: "🇷🇴",
      code: "RO",
      label: "+40",
      value: "+40",
    },
    {
      name: "Russia",
      flag: "🇷🇺",
      code: "RU",
      label: "+7",
      value: "+7",
    },
    {
      name: "Rwanda",
      flag: "🇷🇼",
      code: "RW",
      label: "+250",
      value: "+250",
    },
    {
      name: "Reunion",
      flag: "🇷🇪",
      code: "RE",
      label: "+262",
      value: "+262",
    },
    {
      name: "Saint Barthelemy",
      flag: "🇧🇱",
      code: "BL",
      label: "+590",
      value: "+590",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      flag: "🇸🇭",
      code: "SH",
      label: "+290",
      value: "+290",
    },
    {
      name: "Saint Kitts and Nevis",
      flag: "🇰🇳",
      code: "KN",
      label: "+1869",
      value: "+1869",
    },
    {
      name: "Saint Lucia",
      flag: "🇱🇨",
      code: "LC",
      label: "+1758",
      value: "+1758",
    },
    {
      name: "Saint Martin",
      flag: "🇲🇫",
      code: "MF",
      label: "+590",
      value: "+590",
    },
    {
      name: "Saint Pierre and Miquelon",
      flag: "🇵🇲",
      code: "PM",
      label: "+508",
      value: "+508",
    },
    {
      name: "Saint Vincent and the Grenadines",
      flag: "🇻🇨",
      code: "VC",
      label: "+1784",
      value: "+1784",
    },
    {
      name: "Samoa",
      flag: "🇼🇸",
      code: "WS",
      label: "+685",
      value: "+685",
    },
    {
      name: "San Marino",
      flag: "🇸🇲",
      code: "SM",
      label: "+378",
      value: "+378",
    },
    {
      name: "Sao Tome and Principe",
      flag: "🇸🇹",
      code: "ST",
      label: "+239",
      value: "+239",
    },
  
    {
      name: "Senegal",
      flag: "🇸🇳",
      code: "SN",
      label: "+221",
      value: "+221",
    },
    {
      name: "Serbia",
      flag: "🇷🇸",
      code: "RS",
      label: "+381",
      value: "+381",
    },
    {
      name: "Seychelles",
      flag: "🇸🇨",
      code: "SC",
      label: "+248",
      value: "+248",
    },
    {
      name: "Sierra Leone",
      flag: "🇸🇱",
      code: "SL",
      label: "+232",
      value: "+232",
    },
    {
      name: "Singapore",
      flag: "🇸🇬",
      code: "SG",
      label: "+65",
      value: "+65",
    },
    {
      name: "Slovakia",
      flag: "🇸🇰",
      code: "SK",
      label: "+421",
      value: "+421",
    },
    {
      name: "Slovenia",
      flag: "🇸🇮",
      code: "SI",
      label: "+386",
      value: "+386",
    },
    {
      name: "Solomon Islands",
      flag: "🇸🇧",
      code: "SB",
      label: "+677",
      value: "+677",
    },
    {
      name: "Somalia",
      flag: "🇸🇴",
      code: "SO",
      label: "+252",
      value: "+252",
    },
    {
      name: "South Africa",
      flag: "🇿🇦",
      code: "ZA",
      label: "+27",
      value: "+27",
    },
    {
      name: "South Sudan",
      flag: "🇸🇸",
      code: "SS",
      label: "+211",
      value: "+211",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      flag: "🇬🇸",
      code: "GS",
      label: "+500",
      value: "+500",
    },
    {
      name: "Spain",
      flag: "🇪🇸",
      code: "ES",
      label: "+34",
      value: "+34",
    },
    {
      name: "Sri Lanka",
      flag: "🇱🇰",
      code: "LK",
      label: "+94",
      value: "+94",
    },
    {
      name: "Sudan",
      flag: "🇸🇩",
      code: "SD",
      label: "+249",
      value: "+249",
    },
    {
      name: "Suriname",
      flag: "🇸🇷",
      code: "SR",
      label: "+597",
      value: "+597",
    },
    {
      name: "Svalbard and Jan Mayen",
      flag: "🇸🇯",
      code: "SJ",
      label: "+47",
      value: "+47",
    },
    {
      name: "Swaziland",
      flag: "🇸🇿",
      code: "SZ",
      label: "+268",
      value: "+268",
    },
    {
      name: "Sweden",
      flag: "🇸🇪",
      code: "SE",
      label: "+46",
      value: "+46",
    },
    {
      name: "Switzerland",
      flag: "🇨🇭",
      code: "CH",
      label: "+41",
      value: "+41",
    },
    {
      name: "Syrian Arab Republic",
      flag: "🇸🇾",
      code: "SY",
      label: "+963",
      value: "+963",
    },
    {
      name: "Taiwan",
      flag: "🇹🇼",
      code: "TW",
      label: "+886",
      value: "+886",
    },
    {
      name: "Tajikistan",
      flag: "🇹🇯",
      code: "TJ",
      label: "+992",
      value: "+992",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      flag: "🇹🇿",
      code: "TZ",
      label: "+255",
      value: "+255",
    },
    {
      name: "Thailand",
      flag: "🇹🇭",
      code: "TH",
      label: "+66",
      value: "+66",
    },
    {
      name: "Timor-Leste",
      flag: "🇹🇱",
      code: "TL",
      label: "+670",
      value: "+670",
    },
    {
      name: "Togo",
      flag: "🇹🇬",
      code: "TG",
      label: "+228",
      value: "+228",
    },
    {
      name: "Tokelau",
      flag: "🇹🇰",
      code: "TK",
      label: "+690",
      value: "+690",
    },
    {
      name: "Tonga",
      flag: "🇹🇴",
      code: "TO",
      label: "+676",
      value: "+676",
    },
    {
      name: "Trinidad and Tobago",
      flag: "🇹🇹",
      code: "TT",
      label: "+1868",
      value: "+1868",
    },
    {
      name: "Tunisia",
      flag: "🇹🇳",
      code: "TN",
      label: "+216",
      value: "+216",
    },
    {
      name: "Turkey",
      flag: "🇹🇷",
      code: "TR",
      label: "+90",
      value: "+90",
    },
    {
      name: "Turkmenistan",
      flag: "🇹🇲",
      code: "TM",
      label: "+993",
      value: "+993",
    },
    {
      name: "Turks and Caicos Islands",
      flag: "🇹🇨",
      code: "TC",
      label: "+1649",
      value: "+1649",
    },
    {
      name: "Tuvalu",
      flag: "🇹🇻",
      code: "TV",
      label: "+688",
      value: "+688",
    },
    {
      name: "Uganda",
      flag: "🇺🇬",
      code: "UG",
      label: "+256",
      value: "+256",
    },
    {
      name: "Ukraine",
      flag: "🇺🇦",
      code: "UA",
      label: "+380",
      value: "+380",
    },
    {
      name: "United Arab Emirates",
      flag: "🇦🇪",
      code: "AE",
      label: "+971",
      value: "+971",
    },
    {
      name: "United Kingdom",
      flag: "🇬🇧",
      code: "GB",
      label: "+44",
      value: "+44",
    },
    {
      name: "United States",
      flag: "🇺🇸",
      code: "US",
      label: "+1",
      value: "+1",
    },
    {
      name: "Uruguay",
      flag: "🇺🇾",
      code: "UY",
      label: "+598",
      value: "+598",
    },
    {
      name: "Uzbekistan",
      flag: "🇺🇿",
      code: "UZ",
      label: "+998",
      value: "+998",
    },
    {
      name: "Vanuatu",
      flag: "🇻🇺",
      code: "VU",
      label: "+678",
      value: "+678",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      flag: "🇻🇪",
      code: "VE",
      label: "+58",
      value: "+58",
    },
    {
      name: "Vietnam",
      flag: "🇻🇳",
      code: "VN",
      label: "",
      value: "+84",
    },
    {
      name: "Virgin Islands, British",
      flag: "🇻🇬",
      code: "VG",
      label: "+1284",
      value: "+1284",
    },
    {
      name: "Virgin Islands, U.S.",
      flag: "🇻🇮",
      code: "VI",
      label: "+1340",
      value: "+1340",
    },
    {
      name: "Wallis and Futuna",
      flag: "🇼🇫",
      code: "WF",
      label: "+681",
      value: "+681",
    },
    {
      name: "Yemen",
      flag: "🇾🇪",
      code: "YE",
      label: "+967",
      value: "+967",
    },
    {
      name: "Zambia",
      flag: "🇿🇲",
      code: "ZM",
      label: "+260",
      value: "+260",
    },
    {
      name: "Zimbabwe",
      flag: "🇿🇼",
      code: "ZW",
      label: "+263",
      value: "+263",
    },
  ];
  
  export default countryData;